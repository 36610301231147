<span class="file-uploader-container">
    <!-- <p>{{container.fichier}}</p> -->
    <div class="file-uploader-block">
        <dx-file-uploader #up [selectButtonText]="'applicables-fileupload*fichier-selectButtonText'|translate" labelText=""
            accept=".png,.jpg,.jpeg,.png,.gif,.pdf,.doc,.rtf,.xls,.xslx,.docx,.mp3,.ogg,.txt,.ppt,.pptx,.mp4" uploadMode="useForm"
            [showFileList]="true" (onValueChanged)="onFileUploaderValueChanged($event, container)" 
            >
        </dx-file-uploader>
        <!-- [maxFileSize]="12000000" -->
        <span class="note">{{'applicables-span*taille-maxi'|translate}}: <span>12 {{'accueil-ts*Mo'|translate}}</span>.</span>
    </div>
</span>